<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import {
  getArtistFromChartmetricByName,
  getArtistFromDbByName,
  saveArtistDetailByChartmetricID,
} from "@/api/artists.api";
import {
  capitalizeEveryWordFirstLetter,
  capitalizeFirstLetter,
  routeToPage,
} from "@/utils/staticHelper";
import {
  getTracksFromChartmetricByName,
  getTracksFromDbByName,
  saveTrackDetailByChartmetricID,
} from "@/api/tracks.api";
import { ElNotification } from "element-plus";
import {
  getCuratorsFromChartmetricByName,
  getCuratorsFromDbByName,
  saveCuratorDetailByChartmetricCuratorID,
} from "@/api/curators.api";
import {
  getPlaylistsFromChartmetricByName,
  getPlaylistsFromDbByName,
  savePlaylistDetailByCuratorPlaylistID,
} from "@/api/playlists.api";
import { TypeEnums } from "@/common/enums/typeEnums";
export default {
  name: "SearchResults",
  methods: {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
    routeToPage,
  },
  setup() {
    const loading = ref(false);
    const heading = ref("");
    const route = useRoute();
    const mongoData = ref([]);
    const liveData = ref([]);
    const search = computed(() => {
      return { searchQuery: route.query.search, searchType: route.query.type };
    });
    // const searchType = computed(() => {
    //   return route.query.type;
    // });

    onMounted(() => {
      document.title = "SongReach | Explore";
    });
    const getSearchResultsFromDbByType = async () => {
      try {
        loading.value = true;
        mongoData.value = [];
        liveData.value = [];
        let chartmetricResponse = [];
        if (search.value.searchQuery && search.value.searchType) {
          heading.value =
            "AI " + capitalizeFirstLetter(search.value.searchType) + "s";
          if (search.value.searchType === TypeEnums.ARTIST) {
            const { data } = await getArtistFromDbByName(
              search.value.searchQuery
            );
            mongoData.value = data && data.data ? data.data : [];
            chartmetricResponse = await getArtistFromChartmetricByName(
              search.value.searchQuery
            );
          } else if (search.value.searchType === TypeEnums.TRACK) {
            const { data } = await getTracksFromDbByName(
              search.value.searchQuery
            );
            mongoData.value = data && data.data ? data.data : [];
            chartmetricResponse = await getTracksFromChartmetricByName(
              search.value.searchQuery
            );
          } else if (search.value.searchType === TypeEnums.CURATOR) {
            const { data } = await getCuratorsFromDbByName(
              search.value.searchQuery
            );
            mongoData.value = data && data.data ? data.data : [];
            chartmetricResponse = await getCuratorsFromChartmetricByName(
              search.value.searchQuery
            );
          } else if (search.value.searchType === TypeEnums.PLAYLIST) {
            const { data } = await getPlaylistsFromDbByName(
              search.value.searchQuery
            );
            mongoData.value = data && data.data ? data.data : [];
            chartmetricResponse = await getPlaylistsFromChartmetricByName(
              search.value.searchQuery
            );
          }
          liveData.value =
            chartmetricResponse &&
            chartmetricResponse.data &&
            chartmetricResponse.data.data
              ? chartmetricResponse.data.data
              : [];
        }
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching records",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    const routeToDetailPage = (id) => {
      routeToPage(`${search.value.searchType}-detail/${id}`);
    };
    const saveDataIntoMongo = (id) => {
      try {
        loading.value = true;
        if (search.value.searchType === TypeEnums.ARTIST) {
          saveArtistDetailByChartmetricID(id);
        } else if (search.value.searchType === TypeEnums.TRACK) {
          saveTrackDetailByChartmetricID(id);
        } else if (search.value.searchType === TypeEnums.CURATOR) {
          saveCuratorDetailByChartmetricCuratorID(id);
        } else if (search.value.searchType === TypeEnums.PLAYLIST) {
          savePlaylistDetailByCuratorPlaylistID(id);
        }
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
        routeToDetailPage(id);
      }
    };
    watch(
      () => search.value,
      (value) => {
        if (value) {
          getSearchResultsFromDbByType();
        }
      },
      { immediate: true }
    );
    return {
      heading,
      loading,
      liveData,
      mongoData,
      routeToDetailPage,
      saveDataIntoMongo,
    };
  },
};
</script>
<template>
  <div class="search-results" v-loading.fullscreen.lock="loading">
    <div class="container-fluid mb-20">
      <el-row class="search-list mt-2 mb-10" :gutter="0">
        <h2 class="search-list-head-title ms-12">
          {{ heading }}
        </h2>
        <el-col :span="24">
          <el-row :gutter="24">
            <el-col
              :xl="4"
              :lg="6"
              :md="6"
              :sm="8"
              v-for="(item, key) in mongoData"
              v-bind:key="key"
            >
              <el-card
                class="box-card aside-dark cursor-pointer"
                @click="routeToDetailPage(item.id)"
              >
                <div class="text-center">
                  <el-avatar
                    :size="150"
                    :src="
                      item.image_url
                        ? item.image_url
                        : 'media/avatars/blank.png'
                    "
                  ></el-avatar>
                  <h3 class="artist-name theme-v2-color truncate">
                    {{ capitalizeEveryWordFirstLetter(item.name) }}
                  </h3>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="search-list mt-2 mb-10" :gutter="0">
        <h2 class="search-list-head-title ms-12">Live Data</h2>
        <el-col :span="24">
          <el-row :gutter="24">
            <el-col
              :xl="4"
              :lg="6"
              :md="6"
              :sm="8"
              v-for="(item, key) in liveData"
              v-bind:key="key"
            >
              <el-card
                class="box-card aside-dark cursor-pointer"
                @click="saveDataIntoMongo(item.id)"
              >
                <div class="text-center">
                  <el-avatar
                    :size="150"
                    :src="
                      item.image_url
                        ? item.image_url
                        : 'media/avatars/blank.png'
                    "
                  ></el-avatar>
                  <h3 class="artist-name theme-v2-color truncate">
                    {{ capitalizeEveryWordFirstLetter(item.name) }}
                  </h3>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-list {
  .search-list-head-title {
    margin-top: 24px;
    font-weight: 700;
    color: #33647f;
    font-size: 20px;
  }
  .box-card {
    margin-top: 1.5em;
  }
  .box-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }
}
</style>
